.link-button {
    @apply text-lg font-bold text-white flex items-center gap-x-1;
}

.link-button-underline {
    @apply underline font-bold text-brand-light decoration-brand-light;
}

.btn {
    @apply flex items-center justify-center rounded-[36px] py-5;
}

.btn-primary {
    @apply bg-white px-8 font-bold text-lg text-brand-medium;
}

.btn-primary-reversed {
    @apply text-white px-8 font-bold text-lg bg-brand-darker;
}

.btn-secondary {
    @apply font-bold text-xl bg-gray-lighter text-brand-medium;
}

.btn-brand {
    @apply font-bold text-xl bg-brand-medium text-white;
}

.btn-text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,0.16);
}

.brand-larosa {
    .btn-primary-reversed {
        @apply bg-brand-medium;
    }
}

.brand-focus, .brand-larosa {
    .intro-slider-background {
        @apply bg-brand-dark;
    }
}
