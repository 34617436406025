.brand-qsq {
    --brand-color-light: 234 179 184;
    --brand-color-medium: 218 133 140;
    --brand-color-dark: 199 93 101;
    --brand-color-darker: 184 126 130;
}

.brand-blonie {
    --brand-color-light: 117 163 146;
    --brand-color-medium: 95 133 119;
    --brand-color-dark: 83 116 104;
    --brand-color-darker: 74 103 92;
}

.brand-akardo {
    --brand-color-light: 134 206 221;
    --brand-color-medium: 99 179 202;
    --brand-color-dark: 74 133 150;
    --brand-color-darker: 80 142 160;
}

.brand-marasimsim {
    --brand-color-light: 170 217 216;
    --brand-color-medium: 130 165 165;
    --brand-color-dark: 131 168 167;
    --brand-color-darker: 105 136 136;
}

.brand-focus {
    --brand-color-light: 144 177 228;
    --brand-color-medium: 105 146 215;
    --brand-color-dark: 29 83 173;
    --brand-color-darker: 22 63 131;
}

.brand-larosa {
    --brand-color-light: 123 123 123;
    --brand-color-medium: 70 70 70;
    --brand-color-dark: 27 27 27;
    --brand-color-darker: 27 27 27;
}

.brand-laurella {
    --brand-color-light: 244 182 197;
    --brand-color-medium: 218 133 140;
    --brand-color-dark: 199 93 101;
    --brand-color-darker: 235 163 187;
}

