.scale-60 {
  --tw-scale-x: .6;
  --tw-scale-y: .6;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.brand-filter {
  filter: brightness(0) saturate(100%) invert(65%) sepia(30%) saturate(612%) hue-rotate(307deg) brightness(85%) contrast(98%);
}

.white-filter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(145deg) brightness(102%) contrast(101%);
}

.check-background {
  background-image: url("/assets/images/check.svg");
  background-repeat: no-repeat;
  background-position: right top;
}
