html, body {
    @apply font-poppins;
}

#page-wrapper {
    @apply from-brand-dark to-brand-light;

    &.welcome-page {
            @apply bg-brand-dark/80
    }

    .intro-slider-background {
        @apply bg-brand-light;
    }

  .ngx-pagination .current {
    background: rgb(var(--brand-color-medium)) !important;
  }
}
